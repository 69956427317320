import React from 'react';

import type { ModalProps } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

type Props = {
  type:string
} & ModalProps;

/**
 * Popup destinée à afficher un message d'information pour les utilisateurs
 *  du mode démo, indiquant principalement que la fonctionnalité est n'est pas
 *  disponible pour en mode demo.
 */
export default function PopupDemo({ type, show, onHide }: Props) {

  // Le top serait de destructurer les props d'entrée avec un code du genre
  //   const { type, ...modalProps } = props;
  // puis de passer modalProps au composant Modal, mais mon IDE m'indique une
  // erreur de type, donc je laisse comme ça pour le moment.

  const getContent = () => {
    switch(type) {
      case 'link':
        return "Cette fonctionnalité est réservée aux agences du réseau Guy Hoquet"
      default:
        return "Ce module est réservé aux agences du réseau Guy Hoquet"
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className="special-width-popup popup"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h4 >{getContent()}</h4>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
